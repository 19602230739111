<template>
    <div>
        <div class="col-md-12">
            <div class="row" style="margin-top:20px">
                <div class="col-md-6">
                    <h3 class="page-header">
                        <i class="fa fa fa-user animated bounceInDown show-info"></i>
                        View Scheme List
                    </h3>
                </div>
                <div class="col-md-6 ">
                    <div class="row" style="padding-top:18px;justify-content:flex-end;">
                        <div class="col-md-2" style="margin-right:25px">
                            <button v-if="viewno == 3" @click="back()" class="btn btn-primary"><i class="fa fa-backward"></i>
                                Back</button>
                        </div>
                        <div class="col-md-2" style="margin-right:25px;">
                            <button @click="refresh()" class="btn btn-primary"><i class="fa fa-refresh"></i>
                                Refresh</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-20">
                <div class="col-md-4">
                    <label>Lan No.</label>
                    <input type="text" v-model="lanno" class="form-control form-cascade-control input-small">
                </div>
                <div class="col-md-4">
                    <label>VSDIGI-ID</label>
                    <input type="text" v-model="vsdigiid" class="form-control form-cascade-control input-small">
                </div>
                <div class="col-md-4">
                    <label>Customer Name</label>
                    <input type="text" v-model="name" class="form-control form-cascade-control input-small">
                </div>
                <div class="col-md-4">
                    <label>Mobile no.</label>
                    <input type="number" v-model="mobile" class="form-control form-cascade-control input-small">
                </div>
                <div class="col-md-4 flex-around-row" style="margin-top: 23px;">
                    <button class="btn btn-success" @click="search()" >Search</button>
                    <button class="btn btn-danger" @click="cancel()" >Cancel</button> 
                </div>
            </div>
        </div>

        <template v-if="viewno == 1">

            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-body">
                        <table class="table users-table table-condensed table-hover table-sm" id="tblmanual">
                            <thead class="table-font">
                                <tr>
                                    <th>#</th>
                                    <th>LAN No.</th>
                                    <th>VSDIGI-ID</th>
                                    <th>Customer</th>
                                    <th>Scheme</th>
                                    <th>Sale Date</th>
                                    <th>Start Date</th>
                                    <th>SP</th>
                                    <th>EMI</th>
                                    <th>Entry Date</th>
                                    <th>Entered By</th>
                                    <th style="width:0px">&nbsp;</th>
                                    <th style="width:0px">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody class="table-font">
                                <tr v-for="(item, index) in ongoingschemes" v-bind:key="item.id" :style="isdefaulter(item)">
                                    <td>{{ index + 1 }}</td>
                                    <td><a href="#" @click="details(item)">{{ item.generatedid }}</a></td>
                                    <td>
                                        <div v-if="item.customer != null">{{ item.customer.generatedid }}</div>
                                    </td>
                                    <td>
                                        <div v-if="item.customer != null">{{ item.customer.name }}</div>
                                    </td>
                                    <td><span v-if="item.scheme != null">{{ item.scheme.name }}</span></td>
                                    <td>{{ moment(item.saledate).format('DD-MM-YYYY') }}</td>
                                    <td>{{ moment(item.startdate).format('DD-MM-YYYY') }}</td>
                                    <td><span v-if="item.scheme != null">{{ item.scheme.sp }}</span></td>
                                    <td><span v-if="item.scheme != null">{{ item.scheme.emi }}</span></td>
                                    <td>{{ moment(item.created_at).format("DD-MM-YYYY") }}<br>{{moment(item.created_at).format('h:m:s A')}}</td>
                                    <td>
                                        <div v-if="item.enteredby != null">{{ item.enteredby.name }}</div>
                                    </td>
                                    <td v-if="loggedinuser != null && loggedinuser.accessids.includes('24')"
                                        style="padding-left:0">
                                        <button type="button" class="btn btn-success btn-xs" style="width:28px;padding:1px"
                                            @click="edit(item)">
                                            <i class="fa fa-edit"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-prime btn-xs" @click="updatedefault(item)">
                                            <div v-if="item.isdefaulter == 0">Deactivate</div>
                                            <div v-else>Activate</div>
                                        </button>
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-view btn-xs" @click="updaterefund(item)">
                                            <div v-if="item.isrefund == 0">Refund</div>
                                            <div v-else>Refund Back</div>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </template>
        <on-going-scheme-form v-if="viewno == 2"></on-going-scheme-form>
        <on-going-scheme-details v-if="viewno == 3"></on-going-scheme-details>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import OnGoingSchemeForm from './OnGoingSchemeForm.vue'
import OnGoingSchemeDetails from './OnGoingSchemeDetails.vue'
import Constants from '../../components/utilities/Constants.vue'
import moment from "moment";
export default {
    mixins: [Constants],
    components: { OnGoingSchemeForm, OnGoingSchemeDetails },
    data() {
        return {
            activecount: 0,
            totalemp: 0,
            moment: moment,
            searchstring: '',
            searchdetails: true,
            lanno:'',
            vsdigiid:'',
            name:'',
            mobile:0
        }
    },
    watch: {
        $route: function (to, from) {
            console.log(to)
            console.log(from)
            if (to != from) {
                if (this.$route.params.id != null) {
                    //console.log(this.$route.params.id)
                    this.searchstring = this.$route.params.id
                    // this.search();
                    this.searchdetails = false

                } else {
                    this.searchdetails = true
                    this.refresh();
                }
            }
        },
    },
    computed: {
        ...mapGetters([
            'viewno', 'ongoingschemes', 'schemedetails', 'loggedinuser'
        ]),
    },
    mounted() {
        //this.refresh();
        this.$store.dispatch('fetchemployees')
        this.$store.dispatch('fetchcustomers', 'fetchemployees', 'fetchdefaulterschemes')

        if (this.$route.params.id != null) {
            this.searchstring = this.$route.params.id
            this.searchdetails = false
            //console.log(this.$route.params)
            // this.search();
        } else {
            this.refresh();
            this.searchdetails = true
        }
    },
    methods: {
        isdefaulter(item) {
            if (item.isdefaulter == 1) {
                return "background:rgb(39 53 90);color:#fff;";
            }
            return '';
        },
        updatedefault(item) {
            //if(item.isdefaulter==1){
            let param = { column: 'isdefaulter', id: item.id, value: item.isdefaulter == 1 ? 0 : 1 };
            this.$http.post('api/ongoingschemes/updatefield', param)
                .then((response) => this.processResponsedefaulter(response.data))
                .catch((err) => {
                    console.log(err)
                });
        },
        processResponsedefaulter(data) {
            this.$store.commit('updateappendongoingschemes', data);
            this.refresh();
        },
        updaterefund(item) {
            let parm = { column: 'isrefund', id: item.id, value: item.isrefund == 1 ? 0 : 1 };
            this.$http.post('api/ongoingschemes/updatefield', parm)
                .then((response) => this.processResponseRefund(response.data))
                .catch((err) => {
                    console.log(err)
                });
        },
        processResponseRefund(data) {
            this.$store.commit('updateappendongoingschemes', data);
            this.refresh();
        },

        edit(item) {
            this.$store.commit('assignedititem', item)
            this.$store.commit('assignviewno', 2)
        },
        details(item) {
            let param = { id: item.id }
            this.$http.post('api/ongoingschemes/details', param)
                .then((response) => this.processDetailResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
        },
        processDetailResponse(data) {
            console.log('data')
            console.log(data)
            this.$store.commit('assignidproofs', data.customer.idproofs == null ? [] : data.customer.idproofs)
            this.$store.commit('assignedititem', data)
            this.$store.commit('assignviewno', 3)
        },
        refresh() {
            this.$store.dispatch('fetchschemes');
            this.searchdetails = true
            let param = {}
            this.$http.post('api/ongoingschemes/fetch', param)
                .then((response) => this.processResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
        },
        processResponse(data) {
            this.$store.commit('assignongoingschemes', data)
        },
        back() {
            this.$store.commit('assignviewno', 1)
        },
        initialize(){
            this.lanno='',
            this.vsdigiid='',
            this.name='',
            this.mobile=0
            
        },
        cancel(){
            this.initialize();
            this.refresh();
        },
        search(){
            let param={
                lanno:this.lanno,
                vsdigiid:this.vsdigiid,
                name:this.name,
                mobile:this.mobile
            }
            this.$http.post('api/scheduled/searchviewscheme', param)
                .then((response) => this.processSearchResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
        },
        processSearchResponse(data){
            this.$store.commit('assignongoingschemes', data)
        }

    }
}
</script>

<style scoped>.btn-primary:hover {
    text-decoration: none !important;
    background-color: #3a476b !important;
}</style>